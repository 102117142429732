import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import Icons from '@aurora/shared-client/icons';
import type { TagPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Category, Tag } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  EndUserComponent,
  EndUserPages,
  EndUserPathParams,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ItemType, TagViewVariant } from '../../../../types/enums';
import { TagInlineMetadata } from '../../../../types/enums';
import type { ItemViewVariantFC } from '../../../entities/types';
import NodeLink from '../../../nodes/NodeLink/NodeLink';
import useTranslation from '../../../useTranslation';
import localStyles from './TagViewInline.module.pcss';

/**
 * Tag View - Inline
 *
 * @constructor
 * @author Manasvini Arul
 */
const TagViewInline: ItemViewVariantFC<Tag, ItemType.TAG, TagViewVariant.INLINE> = ({
  entity: tag,
  className,
  useIcon,
  useLastActivityTime,
  usePostCount,
  inlineMetadata,
  node,
  iconClassName,
  tagTextClassName,
  useNode,
  iconSize,
  useIconFrame
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage } = useTranslation(EndUserComponent.TAG_VIEW_INLINE);
  const { contextNode } = useContext(AppContext);
  const { formatRelativeTime } = useDateTime();
  const { Link } = useEndUserRoutes();
  const isCommunity = node
    ? node.nodeType === NodeType.CATEGORY && node.displayId === 'top'
    : contextNode.nodeType === NodeType.COMMUNITY;
  const nodeId = node ? node.id : contextNode.id;
  const time = tag.lastActivityTime;

  const queryParams = !isCommunity
    ? {
        [EndUserQueryParams.NODE_ID]: nodeId
      }
    : {};

  /**
   * Renders the tag icon
   */
  function renderTagIcon(): React.ReactElement {
    return (
      <Link<TagPagesAndParams>
        route={EndUserPages.TagPage}
        params={{ [EndUserPathParams.TAG_NAME]: tag.text }}
        query={queryParams}
      >
        <a aria-label={formatMessage('tagIconLink', { name: tag.text })}>
          <Icon
            icon={Icons.ContentTagIcon}
            size={iconSize}
            color={IconColor.GRAY_600}
            className={cx(iconClassName, 'lia-g-mt-5 lia-g-mr-5', 'flex-shrink-0')}
            useFrame={useIconFrame}
          />
        </a>
      </Link>
    );
  }

  /**
   * Renders the tag text
   */
  function renderTagText(): React.ReactElement {
    return (
      <span className={cx('lia-tag-text')}>
        <Link<TagPagesAndParams>
          route={EndUserPages.TagPage}
          params={{ [EndUserPathParams.TAG_NAME]: tag.text }}
          query={queryParams}
        >
          <a
            data-testid="tagLink"
            className={cx('lia-tag-link lia-g-mb-0 lia-g-clamp lia-g-clamp-1', tagTextClassName)}
            title={tag.text}
          >
            {tag.text}
          </a>
        </Link>
      </span>
    );
  }

  /**
   * Renders the tag last used timestamp
   */
  function renderLastActivityTime(): React.ReactElement {
    const showLastUsedTimeInline = inlineMetadata === TagInlineMetadata.LAST_ACTIVITY_TIME;
    return (
      <span
        className={cx('lia-byline-item lia-g-divider', {
          'lia-tag-meta-data': showLastUsedTimeInline
        })}
        data-testid="TagLastActivityTime"
      >
        {formatMessage('lastActivityTime', {
          key: showLastUsedTimeInline,
          value: formatRelativeTime(time)
        })}
      </span>
    );
  }

  /**
   * Renders the number of posts in which the tag is used
   */
  function renderPostCount(): React.ReactElement {
    return (
      <span
        className={cx('lia-byline-item lia-g-divider flex-shrink-0', {
          'lia-tag-meta-data': inlineMetadata === TagInlineMetadata.POST_COUNT
        })}
        data-testid="TagPostCount"
      >
        {formatMessage('postCount', { postCount: tag.messagesCount })}
      </span>
    );
  }

  /**
   * Renders the node in which the tag is being subscribed
   */
  function renderNode(): React.ReactElement {
    const isNodeCommunity = node.nodeType === NodeType.CATEGORY && node.displayId === 'top';
    return (
      <span>
        <div className={cx('d-flex')}>
          {isNodeCommunity ? (
            <Icon
              icon={Icons.HomeIcon}
              size={IconSize.PX_12}
              color={IconColor.GRAY_900}
              className={cx('lia-icon')}
            />
          ) : (
            <NodeIcon node={node} className={cx('lia-icon')} size={IconSize.PX_12} />
          )}
          <NodeLink node={isNodeCommunity ? (node as Category).parent : node}>
            {isNodeCommunity ? (
              <p className={cx(' lia-byline-item')}>{formatMessage('community')}</p>
            ) : (
              <NodeTitle
                node={node}
                as="span"
                className={cx('lia-title lia-g-clamp lia-byline-item')}
              />
            )}
          </NodeLink>
        </div>
      </span>
    );
  }

  return (
    <article data-testid="TagViewInline" className={cx(className)}>
      <div className={cx('lia-tag')}>
        {useIcon && renderTagIcon()}
        {inlineMetadata === TagInlineMetadata.NONE ? (
          <div>
            {renderTagText()}
            {useNode && node && renderNode()}
            {useLastActivityTime && renderLastActivityTime()}
            {usePostCount && renderPostCount()}
          </div>
        ) : (
          <>
            {renderTagText()}
            {useLastActivityTime && renderLastActivityTime()}
            {usePostCount && renderPostCount()}
          </>
        )}
      </div>
    </article>
  );
};
export default TagViewInline;
